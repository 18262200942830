import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchVouchers } from '../../redux/slices/vouchersSlice';
import { useTranslation } from 'react-i18next';

function Vouchers() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchVouchers());
    }, [dispatch]);

    const vouchers = useSelector((state) => state.vouchers.vouchers);

    const expirationDate = (createdAt, validMonths) => {
        const createdDate = new Date(createdAt);
        createdDate.setMonth(createdDate.getMonth() + validMonths);
        return createdDate.toLocaleDateString('en-GB');  // Format as needed
    };

    const isExpired = (createdAt, validMonths) => {
        const createdDate = new Date(createdAt);
        const expirationDateUsFormat = createdDate.setMonth(createdDate.getMonth() + validMonths);
        return new Date(expirationDateUsFormat) < new Date();
    }

    const uniqueGrantees = {};
    if (vouchers.length > 0) {
        vouchers.forEach(voucher => {
            voucher.grantees.forEach(grantee => {
                if (!uniqueGrantees[grantee.grantee_name]) {
                    uniqueGrantees[grantee.grantee_name] = grantee.grantee_logo;
                }
            });
        });
    }
    const grantees = Object.keys(uniqueGrantees).map(name => ({
        name,
        logo: uniqueGrantees[name],
    }));

    return (
        <div className="container mt-5">
            <h2 className="mt-5 mb-3">{t('vouchers.vouchers')}</h2>
            <hr className="mb-5"/>
            {vouchers.length > 0 ? (
                <div className="row">
                    {vouchers.map((voucher) => (
                        <div 
                            key={voucher.voucher_id} 
                            className={`${isExpired(voucher.created_at, voucher.valid_months) && voucher.valid_months ? 'd-none' : ''} col-lg-4`}
                        >
                            <Link to={`/vouchers/${voucher.voucher_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                <div className="card text-center mb-4">
                                    <div className="card-body">
                                    <div className="text-center">
                                            <img 
                                                src={`https://storage.googleapis.com/sponsify/sponsors-logo/${voucher.sponsor_logo}`} 
                                                className=""
                                                height="50px" 
                                                alt={voucher.sponsor_name}
                                                onError={(e) => {
                                                    e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                                }}
                                            />
                                            <p className="small">{voucher.sponsor_name}</p>
                                        </div>
                                        <div className="fs-1 fw-bold">{Math.round(voucher.discount)}<span className="fs-5 fw-normal">%</span></div>
                                        <div>{t('vouchers.off')}</div>
                                        {voucher.purchase_over > 0 ? (
                                            <p className="card-text">{t('vouchers.purchasesOver')} €{voucher.purchase_over}</p>
                                        ) : (
                                            <p className="card-text">{t('vouchers.purchasesOverAnyPrice')}</p>
                                        )}
                                        
                                        {voucher.valid_months > 0 ? (
                                            <p className="card-text small my-0">{t('vouchers.validTill')} {expirationDate(voucher.created_at, voucher.valid_months)}</p>
                                        ) : (
                                            <p className="card-text small my-0">{t('vouchers.validIndefinitely')}</p>
                                        )}
                                        <p className="card-text small">{t('vouchers.notValidOnlineStore')}</p>
                                        
                                        <hr/>

                                        <div className="row">  
                                            {grantees.map((grantee, index) => (
                                                <div key={index} className="col-4">
                                                    <img 
                                                        src={`https://storage.googleapis.com/sponsify/grantees-logo/${grantee.logo}`} 
                                                        className=""
                                                        height="50px" 
                                                        alt={voucher.sponsor_name}
                                                        onError={(e) => {
                                                            e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                                        }}
                                                    />
                                                    <p className="small">{grantee.name}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            ) : (
                <p>{t('vouchers.noVouchersFound')}.</p>
            )}
        </div>
    );
}

export default Vouchers;