import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function PreRegistration() {
    const { t } = useTranslation();

    return (
        <div className="container text-center mt-5">
            <div className="row mt-5 justify-content-center">
                <div className="">
                    <div className="d-grid gap-5 position-absolute top-50 start-50 translate-middle">
                        <Link to="/grantee-registration" className="btn btn-lg btn-primary p-lg-4" type="button">
                            {t('registration.registerAsGrantee')}
                        </Link>
                        <Link to="/sponsor-registration" className="btn btn-lg btn-primary p-lg-4" type="button">
                            {t('registration.registerAsSponsor')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PreRegistration;
