import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'; 
import { useTranslation } from 'react-i18next';

import { fetchProjectsByUserId } from '../../redux/slices/projectsByUserIdSlice';
import { fetchPlansByGranteeId } from '../../redux/slices/plansByGranteeIdSlice';
import { fetchGranteeByUserId } from '../../redux/slices/granteeByUserIdSlice';
import { checkConnectedAccountStatus } from '../../redux/slices/checkConnectedAccountStatusSlice';
import { createAccountLink } from '../../redux/slices/createAccountLinkSlice';


function GranteeDashboard() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;

    const projects = useSelector((state) => state.projectsByUserId.projects);
    const plans = useSelector((state) => state.plansByGranteeId.plansByGranteeId);
    const grantee = useSelector((state) => state.granteeByUserId.granteeByUserId);
    const connectedAccountStatus = useSelector((state) => state.checkConnectedAccountStatus.connectedAccountStatus);
    const accountLink = useSelector((state) => state.createAccountLink.accountLink);

    const connectedAccountId = grantee?.stripe_connected_account_id;

    useEffect(() => {
        if (userId) {
            dispatch(fetchProjectsByUserId(userId));
            dispatch(fetchGranteeByUserId(userId));
        }
        dispatch(fetchPlansByGranteeId());
    }, [userId, dispatch]);

    useEffect(() => {
        if (connectedAccountId) {
            dispatch(checkConnectedAccountStatus(connectedAccountId));
            dispatch(createAccountLink(connectedAccountId));
        }
    }, [connectedAccountId, dispatch]);

    return (
        <div className="container mt-5 pt-5">
            {connectedAccountStatus && accountLink && connectedAccountStatus.status === 'restricted' && 
                <div className="alert alert-danger">
                    <p>{t('onboarding.restrictedAccountMessage')}</p>
                    <Link to={accountLink.url} className="btn btn-sponsify-red me-3" aria-label="Complete bank details setup">
                        {t('buttons.completeSetup')}
                    </Link>
                </div>
            }
            <h3 className="">{t('projects.myProjects')} 
                <span className="ms-3">
                    <Link to={`/create-project`} className="btn btn-primary" type="button">{t('projects.createProject')}</Link>
                </span>
            </h3>
            <hr className="mb-3"/>
            <table className="table mb-5">
                <tbody>
                    {projects.length > 0 && projects?.map((project) => (
                        <tr key={project.project_id}>
                            <td>
                                <img 
                                    src={`https://storage.googleapis.com/sponsify/grantees-logo/${project.grantee_logo}`} 
                                    alt={project.project_name}
                                    height="50px"
                                    onError={(e) => {
                                        e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                    }} 
                                />
                            </td>
                            <td>{project.project_name}</td>
                            <td>
                                <Link to={`/update-project/${project.project_id}`} className="btn btn-primary" type="button">{t('buttons.edit')}</Link>
                            </td> 
                            {/* <td>
                                <Link to={`/create-plan/project/${project.project_id}`} className="btn btn-primary" type="button">{t('plans.createPlan')}</Link>
                            </td>  */}
                            <td>
                                <Link to={`/plan-templates/project/${project.project_id}`} className="btn btn-primary" type="button">{t('plans.addPlan')}</Link>
                            </td>                        
                        </tr>
                    ))}
                </tbody>
            </table>
            <h3 className="">{t('plans.myPlans')}</h3>
            <hr className="mb-3"/>
            <table className="table">
                <thead>
                    <tr>
                        <th>{t('plans.plan')}</th>
                        <th>{t('projects.project')}</th>
                        <th>{t('plans.price')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {plans.length > 0 && plans?.map((plan, index) => (
                        <tr key={index}>
                            <td>{plan.plan_name}</td>
                            <td>{plan.project_name}</td>
                            <td>€{plan.plan_prices[0].plan_price.toFixed(2)}/{t('general.mo')}</td>
                            <td>
                                <Link to={`/update-plan/${plan.plan_id}`} className="btn btn-primary" type="button">{t('buttons.edit')}</Link>
                            </td>                         
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )

}

export default GranteeDashboard;
  