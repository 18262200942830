import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchProject } from '../../redux/slices/projectSlice';
import { updateProject } from '../../redux/slices/updateProjectSlice';
import { fetchImagesByProjectId } from '../../redux/slices/imagesByProjectIdSlice';
import { removeImage } from '../../redux/slices/removeImageSlice';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const UpdateProject = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { projectId } = useParams();

    const updateProjectStatus = useSelector((state) => state.updateProject.status);
    const project = useSelector((state) => state.project.project);
    const images = useSelector((state) => state.imagesByProjectId.images);
   
    const [files, setFiles] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
    });

    useEffect(() => {
        if (projectId) {
            dispatch(fetchProject(projectId));
            dispatch(fetchImagesByProjectId(projectId));
        }
    }, [dispatch, projectId]);

    // Pre-fill the form with project data
    useEffect(() => {
        if (project) {
            setFormData({
                name: project.project_name || '',
                description: project.project_description || '',
            });
        }
    }, [project]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxFiles: 5
    });

    const handleDropzoneRemoveFile = (event, fileName) => {
        event.stopPropagation(); // Prevent the click event from bubbling up to the dropzone
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    };

    const handleRemoveImage = (imageId) => {
        dispatch(removeImage({ imageId, projectId }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object to send files along with other form data
        const formDataWithImages = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            formDataWithImages.append(key, value);
        });
        files.forEach((file, index) => {
            formDataWithImages.append(`images`, file); // append each file
        })

        // Dispatch action to create project
        dispatch(updateProject({ projectId, formDataWithImages }));
        setTimeout(() => {
            navigate('/');
        }, 1000); // Wait for 1 second before navigating to ensure fetchItems completes

    }

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">Edit Project</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">{t('projects.name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="name"
                                name="name"
                                autoComplete="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="description">{t('projects.description')}</label>
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                autoComplete="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                                rows="4"
                            />
                        </div>
                        <div className="mb-3">
                            <div {...getRootProps({ className: 'dropzone' })} className="dropzone border-secondary-subtle rounded-2">
                                <input {...getInputProps()} />
                                <p className="small text-secondary">{t('projects.dropzone')}</p>
                                <div className="dropzone-file-list">
                                    {files?.map((file, index) => (
                                        <p key={index}>
                                            {file.name}
                                            <button 
                                                type="button" 
                                                className="dropzone-remove-file-button" 
                                                onClick={(e) => handleDropzoneRemoveFile(e, file.name)}
                                            >
                                                &times;
                                            </button>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {images.map(image => (
                                <div className="col-lg-4 col-md-4 col-sm-6" key={image.id}>
                                    <img 
                                        src={`https://storage.googleapis.com/sponsify/projects/${image.image}`} 
                                        className="img-fluid mb-3 me-3" 
                                        width="150px" 
                                        height="auto" 
                                        alt={image.image} 
                                    />
                                    <span>
                                        <button 
                                            onClick={() => handleRemoveImage(image.id)}
                                            type="button" 
                                            className="btn-close" 
                                            aria-label="Close"
                                        ></button>
                                    </span>
                                </div>
                            ))}
                        </div>  
                        <div className="">
                            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={updateProjectStatus === 'loading'}>
                                {updateProjectStatus === 'loading' ? 'Updating Project...' : t('buttons.submit')}
                            </button>
                        </div>
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default UpdateProject;