import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { updatePlan } from '../../redux/slices/updatePlanSlice';
import { fetchPlan } from '../../redux/slices/planSlice';
import { useTranslation } from 'react-i18next';
import BenefitFields from './BenefitFields';


const UpdatePlan = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { planId } = useParams();

    const updatePlanStatus = useSelector((state) => state.updatePlan.status);
    const plan = useSelector((state) => state.plan.plan);

    const planUserId = plan?.user_id;

    useEffect(() => {
        dispatch(fetchPlan(planId)); 
    }, [dispatch, planId]);

    const [formData, setFormData] = useState({
        name: '',
        price: '',
        benefits: []
    });

    // Pre-fill the form with plan data
    useEffect(() => {
        if (plan) {
            setFormData({
                name: plan.plan_name || '',
                price: plan.plan_prices[0].plan_price || '',
                benefits: plan.plan_benefits.map(benefit => ({
                    benefit_id: benefit.benefit_id,
                    description: benefit.description
                })) || []
            });
        }
    }, [plan]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const updatedFormData = { ...formData, planUserId: planUserId };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Dispatch action to update plan
        dispatch(updatePlan({ updatedFormData, planId }));
        setTimeout(() => {
            navigate(`/projects/${plan.project_id}`);
        }, 1000); 
    }

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">{t('plans.editPlan')}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-11">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="name">{t('plans.name')}</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        id="name"
                                        name="name"
                                        autoComplete="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="price">{t('plans.pricePerMonth')}</label>
                                    <input
                                        className="form-control"
                                        type="number"
                                        min="0.00"
                                        max="10000.00"
                                        step="0.01"
                                        id="price"
                                        name="price"
                                        autoComplete="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                        required
                                    />       
                                </div>
                            </div>
                        </div>
                        <BenefitFields
                            initialBenefits={formData.benefits} 
                            onChange={(updatedBenefits) => setFormData({ ...formData, benefits: updatedBenefits })}
                        />
                        <div className="row">
                            <div className="col-11">
                                <div className="">
                                    <button type="submit" className="btn btn-lg btn-primary w-100" disabled={updatePlanStatus === 'loading'}>
                                        {updatePlanStatus === 'loading' ? 'Updating Plan...' : t('buttons.submit')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>  
            </div>    
        </div>
    );
};

export default UpdatePlan;