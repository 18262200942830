import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createProject } from '../../redux/slices/createProjectSlice';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';


const CreateProject = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const createProjectStatus = useSelector((state) => state.createProject.status);

    const [files, setFiles] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const onDrop = useCallback((acceptedFiles) => {
        setFiles(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        maxFiles: 5
    });

    const handleDropzoneRemoveFile = (event, fileName) => {
        event.stopPropagation(); // Prevent the click event from bubbling up to the dropzone
        setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Create FormData object to send files along with other form data
        const formDataWithImages = new FormData();
        Object.entries(formData).forEach(([key, value]) => {
            formDataWithImages.append(key, value);
        });
        files.forEach((file, index) => {
            formDataWithImages.append(`images`, file); // append each file
        })

        // Dispatch action to create project
        dispatch(createProject(formDataWithImages));
        setTimeout(() => {
            navigate('/');
        }, 1000); // Wait for 1 second before navigating to ensure fetchItems completes

    }

    return (
        <div className="container-fluid">
            <div className="row mt-5 pt-5 justify-content-center">
                <div className="col-lg-6">
                    <h2 className="text-center">{t('projects.createProject')}</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="name">{t('projects.name')}</label>
                            <input
                                className="form-control"
                                type="text"
                                id="name"
                                name="name"
                                autoComplete="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="description">{t('projects.description')}</label>
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                autoComplete="description"
                                value={formData.description}
                                onChange={handleChange}
                                required
                                rows="4"
                            />
                        </div>
                        <div className="mb-3">
                            <div {...getRootProps({ className: 'dropzone' })} className="dropzone border-secondary-subtle rounded-2">
                                <input {...getInputProps()} />
                                <p className="small text-secondary">{t('projects.dropzone')}</p>
                                <div className="dropzone-file-list">
                                    {files?.map((file, index) => (
                                        <p key={index}>
                                            {file.name}
                                            <button 
                                                type="button" 
                                                className="dropzone-remove-file-button" 
                                                onClick={(e) => handleDropzoneRemoveFile(e, file.name)}
                                            >
                                                &times;
                                            </button>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <button type="submit" className="btn btn-lg btn-primary w-100" disabled={createProjectStatus === 'loading'}>
                                {createProjectStatus === 'loading' ? 'Creating Project...' : t('buttons.submit')}
                            </button>
                        </div>
                    </form>   
                </div>
            </div>    
        </div>
    );
};

export default CreateProject;