import React, { useEffect,  useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import {useDispatch, useSelector } from 'react-redux'; 
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import Projects from './components/projects/Projects';
import ProjectPage from './components/projects/ProjectPage';
import CreateProject from './components/projects/CreateProject';
import UpdateProject from './components/projects/UpdateProject';
import CreatePlan from './components/plans/CreatePlan';
import UpdatePlan from './components/plans/UpdatePlan';
import PlanTemplates from './components/plans/PlanTemplates';
import Sponsors from './components/sponsors/Sponsors';
import SponsorPage from './components/sponsors/SponsorPage';
import SponsorDashboard from './components/sponsors/SponsorDashboard';
import PreRegistration from './components/registration/PreRegistration';
import SponsorRegistration from './components/registration/SponsorRegistration';
import GranteeRegistration from './components/registration/GranteeRegistration';
import GranteeDashboard from './components/grantees/GranteeDashboard';
import RegistrationSuccessPage from './components/registration/RegistrationSuccessPage';
import EmailVerificationSuccessPage from './components/registration/EmailVerificationSuccessPage';
import PrivacyPolicy from './components/registration/PrivacyPolicy';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import { loginSuccess } from './redux/slices/authenticationSlice';
import PaymentFlow from './components/payment/PaymentFlow';
import QrCodeGenerator from './components/qrcodes/QrCodeGenerator';
import QrCodes from './components/qrcodes/QrCodes';
import QrCodeScanner from './components/qrcodes/QrCodeScanner';
import CreateVoucher from './components/vouchers/CreateVoucher';
import Vouchers from './components/vouchers/Vouchers';
import Voucher from './components/vouchers/Voucher';
import ApproveVoucherRedemption from './components/vouchers/ApproveVoucherRedemption';
import VoucherRedemptions from './components/vouchers/VoucherRedemptions';
import OnboardingRefresh from './components/onboarding/OnboardingRefresh';
import OnboardingComplete from './components/onboarding/OnboardingComplete';


function App() {
  //  Store isLoggedIn to maintain the persistence of the login session
  const dispatch = useDispatch();
  const [authChecked, setAuthChecked] = useState(false);

  const userData = JSON.parse(localStorage.getItem('userData'));
  const sponsorUser = userData?.user?.user_type === 'sponsor';
  const granteeUser = userData?.user?.user_type === 'grantee';

  const isLoggedIn = useSelector(state => state.authentication.isLoggedIn);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem('isLoggedIn');
    if (storedIsLoggedIn === 'true') {
      dispatch(loginSuccess()); // Dispatch a login success action to set isLoggedIn to true
    }
	setAuthChecked(true);
  }, [dispatch]);

  if (!authChecked) {
    return (
		<div className="position-absolute top-50 start-50 translate-middle">
			<div className="spinner-border" role="status">
				<span className="visually-hidden">Loading...</span>
			</div>
		</div>
	)
  }
  
  return (
    <Router>
			<Navbar />
					<Routes>
						<Route exact path="/" element={<Projects />} />
						<Route path="/projects/:projectId" element={<ProjectPage />} />
						<Route path="/create-project" element={isLoggedIn && granteeUser ? <CreateProject /> : isLoggedIn && !granteeUser ? <Navigate to="/" /> : <Navigate to="/login" />} />
						<Route path="/update-project/:projectId" element={isLoggedIn && granteeUser ? <UpdateProject /> : isLoggedIn && !granteeUser ? <Navigate to="/" /> : <Navigate to="/login" />} />
						<Route path="/create-plan/project/:projectId" element={isLoggedIn && granteeUser ? <CreatePlan /> : isLoggedIn && !granteeUser ? <Navigate to="/" /> : <Navigate to="/login" />} />
						<Route path="/update-plan/:planId" element={isLoggedIn && granteeUser ? <UpdatePlan /> : isLoggedIn && !granteeUser ? <Navigate to="/" /> : <Navigate to="/login" />} />
						<Route path="/plan-templates/project/:projectId" element={isLoggedIn && granteeUser ? <PlanTemplates /> : isLoggedIn && !granteeUser ? <Navigate to="/" /> : <Navigate to="/login" />} />
						<Route exact path="/sponsors" element={<Sponsors />} />
						<Route path="/sponsors/:sponsorId" element={<SponsorPage />} />
						<Route path="/sponsor-dashboard/" element={isLoggedIn && sponsorUser ? <SponsorDashboard /> : isLoggedIn && !sponsorUser ? <Navigate to="/" /> : <Navigate to="/login" />} />
						<Route path="/pre-registration" element={<PreRegistration />} />
						<Route path="/sponsor-registration" element={<SponsorRegistration />} />
						<Route path="/grantee-registration" element={<GranteeRegistration />} />
						<Route path="/grantee-dashboard" element={<GranteeDashboard />} />
						<Route path="/registration-success" element={<RegistrationSuccessPage />} />
						<Route path="/email-verification-success" element={<EmailVerificationSuccessPage />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/login" element={<Login />} />
						<Route path="/logout" element={<Logout />} />
						<Route path="/payment-flow" element={<PaymentFlow />} />
						<Route path="/generate-qrcode" element={<QrCodeGenerator />} />
						<Route path="/qrcodes" element={<QrCodes />} />
						<Route path="/qrcode-scanner" element={<QrCodeScanner />} />
						<Route path="/create-voucher" element={isLoggedIn ? <CreateVoucher /> : <Navigate to="/login" />} />
						<Route path="/vouchers" element={<Vouchers />} />
						<Route path="/vouchers/:voucherId" element={<Voucher />} />
						<Route path="/vouchers/approve-redemption" element={<ApproveVoucherRedemption />} />
						<Route path="/voucher-redemptions/:voucherIdentifier/:voucherId" element={<VoucherRedemptions />} />
						<Route path="/onboarding/refresh/:connectedAccountId" element={<OnboardingRefresh />} />
						<Route path="/onboarding/complete" element={<OnboardingComplete />} />
					</Routes>
			<Footer />
    </Router>
  );
}

export default App;


