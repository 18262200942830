import authenticationReducer from './slices/authenticationSlice';
import sponsorRegistrationReducer from './slices/sponsorRegistrationSlice';
import granteeRegistrationReducer from './slices/granteeRegistrationSlice';
import granteeByUserIdReducer from './slices/granteeByUserIdSlice';
import checkConnectedAccountStatusReducer from './slices/checkConnectedAccountStatusSlice';
import createProjectReducer from './slices/createProjectSlice';
import projectsReducer from './slices/projectsSlice';
import projectReducer from './slices/projectSlice';
import updateProjectReducer from './slices/updateProjectSlice';
import projectsByUserIdReducer from './slices/projectsByUserIdSlice';
import projectsBySponsorIdReducer from './slices/projectsBySponsorIdSlice';
import imagesByProjectIdReducer from './slices/imagesByProjectIdSlice';
import removeImageReducer from './slices/removeImageSlice';
import createPlanReducer from './slices/createPlanSlice';
import plansReducer from './slices/plansSlice';
import planReducer from './slices/planSlice';
import updatePlanReducer from './slices/updatePlanSlice';
import plansByGranteeIdReducer from './slices/plansByGranteeIdSlice';
import plansByProjectIdReducer from './slices/plansByProjectIdSlice';
import planTemplatesReducer from './slices/planTemplatesSlice';
import copyPlanTemplateReducer from './slices/copyPlanTemplateSlice';
import sponsorsReducer from './slices/sponsorsSlice';
import sponsorReducer from './slices/sponsorSlice';
import sponsorsByProjectIdReducer from './slices/sponsorsByProjectIdSlice';
import createCheckoutSessionReducer from './slices/createCheckoutSessionSlice';
import createAccountLinkReducer from './slices/createAccountLinkSlice';
import ordersReducer from './slices/ordersSlice';
import ordersByUserIdReducer from './slices/ordersByUserIdSlice';
import updateCancelAtStripeSubscriptionReducer from './slices/updateCancelAtStripeSubscriptionSlice';
import createStripeBillingPortalReducer from './slices/createStripeBillingPortalSlice';
import generateQrCodeReducer from './slices/generateQrCodeSlice';
import qrCodesReducer from './slices/qrCodesSlice';
import scanQrCodeReducer from './slices/scanQrCodeSlice';
import createVoucherReducer from './slices/createVoucherSlice';
import vouchersReducer from './slices/vouchersSlice';
import voucherReducer from './slices/voucherSlice';
import vouchersBySponsorUserIdReducer from './slices/vouchersBySponsorUserIdSlice';
import redeemVoucherReducer from './slices/redeemVoucherSlice';
import voucherRedemptionsByUserIdReducer from './slices/voucherRedemptionsByUserIdSlice';
import voucherRedemptionsByVoucherIdReducer from './slices/voucherRedemptionsByVoucherIdSlice';
import approveVoucherRedemptionReducer from './slices/approveVoucherRedemptionSlice';
import rejectVoucherRedemptionReducer from './slices/rejectVoucherRedemptionSlice';

const rootReducer = {
    authentication: authenticationReducer,
    sponsorRegistration: sponsorRegistrationReducer,
    granteeRegistration: granteeRegistrationReducer,
    granteeByUserId: granteeByUserIdReducer,
    checkConnectedAccountStatus: checkConnectedAccountStatusReducer,
    createProject: createProjectReducer,
    projects: projectsReducer,
    project: projectReducer,
    updateProject: updateProjectReducer,
    projectsByUserId: projectsByUserIdReducer,
    projectsBySponsorId: projectsBySponsorIdReducer,
    imagesByProjectId: imagesByProjectIdReducer,
    removeImage: removeImageReducer,
    createPlan: createPlanReducer,
    plans: plansReducer,
    plan: planReducer,
    updatePlan: updatePlanReducer,
    plansByGranteeId: plansByGranteeIdReducer,
    plansByProjectId: plansByProjectIdReducer,
    planTemplates: planTemplatesReducer,
    copyPlanTemplate: copyPlanTemplateReducer,
    sponsors: sponsorsReducer,
    sponsor: sponsorReducer,
    sponsorsByProjectId: sponsorsByProjectIdReducer,
    createCheckoutSession: createCheckoutSessionReducer,
    createAccountLink: createAccountLinkReducer,
    orders: ordersReducer,
    ordersByUserId: ordersByUserIdReducer,
    stripeSubscription: updateCancelAtStripeSubscriptionReducer,
    createStripeBillingPortal: createStripeBillingPortalReducer,
    generateQrCode: generateQrCodeReducer,
    qrCodes: qrCodesReducer,
    scanQrCode: scanQrCodeReducer,
    createVoucher: createVoucherReducer,
    vouchers: vouchersReducer,
    voucher: voucherReducer,
    vouchersBySponsorUserId: vouchersBySponsorUserIdReducer,
    redeemVoucher: redeemVoucherReducer,
    voucherRedemptionsByUserId: voucherRedemptionsByUserIdReducer,
    voucherRedemptionsByVoucherId: voucherRedemptionsByVoucherIdReducer,
    approveVoucherRedemption: approveVoucherRedemptionReducer,
    rejectVoucherRedemption: rejectVoucherRedemptionReducer
}

export default rootReducer;