import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProject } from '../../redux/slices/projectSlice';
import { fetchSponsorsByProjectId } from '../../redux/slices/sponsorsByProjectIdSlice';
import { useTranslation } from 'react-i18next';
import Plans from '../plans/Plans';
import PlansTable from '../plans/PlansTable';

import './Projects.css';

function ProjectPage() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { projectId } = useParams();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData?.user?.id;

    const project = useSelector((state) => state.project.project);
    const connectedAccountId = project ? project.connected_account_id : null;
    const sponsors = useSelector((state) => state.sponsorsByProjectId.sponsors) || [];

    const isAuthUser = project?.user_id === userId;

    useEffect(() => {
        dispatch(fetchSponsorsByProjectId(projectId));
        dispatch(fetchProject(projectId));
    }, [dispatch, projectId]);

    const activeSponsors = Array.isArray(sponsors)
    ? sponsors
        .flatMap(activeSponsor => activeSponsor.sponsors || [])
        .filter(sponsor => !sponsor.subscription_cancel_at || new Date(sponsor.subscription_cancel_at) > new Date())
    : [];

    const uniqueActiveSponsors = Array.from(new Set(activeSponsors.map(sponsor => sponsor.sponsor_id)))
        .map(id => activeSponsors.find(sponsor => sponsor.sponsor_id === id));

    return (
        <div className="container mt-5 pt-5">
            <div className="row">
                <div className="col-lg-4 border rounded-2">
                    <h2>{project.project_name}</h2>
                    <p>
                        <span className="me-1">
                            <img 
                                src={`https://storage.googleapis.com/sponsify/grantees-logo/${project.grantee_logo}`} 
                                alt={project.project_name}
                                height="50px"
                                onError={(e) => {
                                    e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                }} 
                            />
                        </span>
                        {project.grantee_name}
                    </p>
                    <p>{project.project_description}</p>
                </div>
                <div className="col-lg-8">  
                    <div id="carouselExampleIndicators" className="carousel slide">
                        <div className="carousel-indicators pb-3">
                        {project.project_images && project.project_images.map((_, index) => (
                            <button
                                key={index}
                                type="button"
                                data-bs-target="#carouselExampleIndicators"
                                data-bs-slide-to={index}
                                className={index === 0 ? 'active' : ''}
                                aria-current={index === 0 ? 'true' : 'false'}
                                aria-label={`Slide ${index + 1}`}
                            ></button>
                        ))}
                        </div>
                        <div className="carousel-wrapper  border rounded">
                            <div className="carousel-inner carousel-img d-flex align-items-center">
                                {project.project_images && project.project_images.map((image, index) => (
                                    <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                        <img
                                            src={`https://storage.googleapis.com/sponsify/projects/${image}`}
                                            className="d-block w-100"
                                            alt={project.project_name}
                                            onError={(e) => {
                                                e.target.src = 'https://storage.googleapis.com/sponsify/missing-image.svg';
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div> 
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
                <h2 className="mt-5 mb-3">{t('sponsors.sponsors')}</h2>
                <hr className="mb-5"/>
                {uniqueActiveSponsors.length > 0 ? (
                    <div className="row">
                        {uniqueActiveSponsors.map((sponsor) => (
                            <div key={sponsor.order_id} className="col-lg-2">
                                <Link to={`/sponsors/${sponsor.sponsor_id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <div className="text-center">
                                        <img 
                                            src={`https://storage.googleapis.com/sponsify/sponsors-logo/${sponsor.sponsor_logo}`} 
                                            className="img-thumbnail" 
                                            alt={sponsor.name}
                                            onError={(e) => {
                                                e.target.src = '/images/companies-logo/missing-image.svg';
                                            }}
                                        />
                                        <h4 className="mt-3">{sponsor.sponsor_name}</h4>  
                                    </div> 
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>{t('sponsors.becomeOurFirstSponsor')}</p>
                )}
                <h2 className="mt-5 mb-3">{t('sponsors.becomeOurSponsor')}
                    {isAuthUser &&
                        <span className="ms-3">
                            <Link to={`/plan-templates/project/${projectId}`} className="btn btn-primary" type="button">{t('plans.addPlan')}</Link>
                        </span>
                    }
                </h2>
                <hr className="mb-5"/>
                {/* Show PlansTable on large screens (lg and up) */}
                <div className="d-none d-lg-block">
                    <PlansTable projectId={projectId} connectedAccountId={connectedAccountId} />
                </div>
                
                {/* Show Plans on small and medium screens (less than lg) */}
                <div className="d-block d-lg-none">
                    <Plans projectId={projectId} connectedAccountId={connectedAccountId} />
                </div>
            </div>
        </div>
    );
}

export default ProjectPage;
