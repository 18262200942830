import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchPlansByProjectId } from '../../redux/slices/plansByProjectIdSlice';
import { createCheckoutSession } from '../../redux/slices/createCheckoutSessionSlice';
import { useTranslation } from 'react-i18next';
// import './Plans.css'

function Plans({ projectId, connectedAccountId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const userId = userData?.user?.id;
    const sponsorUser = userData?.user?.user_type === 'sponsor';

    const [message, setMessage] = useState('');

    const plansByProjectId = useSelector((state) => state.plansByProjectId.plans);
    
    let isAuthUser = false;
    if (Array.isArray(plansByProjectId)) {
        isAuthUser = plansByProjectId?.some(plan => plan.user_id === userId);
    };

    useEffect(() => {
        dispatch(fetchPlansByProjectId(projectId));
    }, [dispatch, projectId]);

    const handleSponsorNow = async (stripeLookupKey) => {
        if (userId && sponsorUser) {
            try {
                const response = await dispatch(createCheckoutSession({ userId, connectedAccountId, stripeLookupKey })).unwrap();
                window.location.href = response.url; // Redirect to the Stripe checkout page
              } catch (error) {
                console.error('Error creating checkout session:', error);
                // Handle error appropriately
              }
        } else if(!userId) {
            navigate('/login');
        } else {
            setMessage(t('plans.warning'));
        }
    };

    return (
        <>
            {message && <div className="alert alert-warning">{message}</div>}
            {plansByProjectId.length > 0 ? (
                <div className="row">
                    {plansByProjectId.map((plan, index) => (
                        <div className="col-lg-4 mb-4" key={index}>
                            <div className="card">
                                <div className="card-body d-flex flex-column justify-content-center">
                                    <h4 className="card-title text-center">{plan.plan_name}</h4>
                                    <h2 className="card-title text-center pricing-card-title">
                                        {plan.plan_price === 0.00 ? 'Free' : `€${plan.plan_prices[0].plan_price.toFixed(2)}`} 
                                        <small className="fs-6">/{t('general.mo')}</small>
                                    </h2>
                                    <ul className="list-group list-group-flush mt-3 mb-4">
                                        {plan.plan_benefits.map((benefit) => (
                                            <li className="list-group-item" key={benefit.benefit_id}>{benefit.description}</li>
                                        ))}
                                    </ul>
                                    {isAuthUser? (
                                        <Link  
                                            to={`/update-plan/${plan.plan_id}`}
                                            type="button" 
                                            className="btn btn-lg btn-block btn-primary"
                                        >
                                            {t('buttons.edit')}
                                        </Link>
                                    ) : (
                                        <button  
                                            onClick={() => handleSponsorNow(plan.plan_prices[0].stripe_lookup_key)} 
                                            type="button" 
                                            className="btn btn-lg btn-block btn-primary"
                                        >
                                            {t('buttons.sponsorNow')}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="container">{t('plans.sponsorshipPlanDisplay')}</div>
            )}
        </>
    );
};

export default Plans;