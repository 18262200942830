import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchPlansByProjectId } from '../../redux/slices/plansByProjectIdSlice';
import { createCheckoutSession } from '../../redux/slices/createCheckoutSessionSlice';
import { useTranslation } from 'react-i18next';
// import './Plans.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function PlansTable({ projectId, connectedAccountId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const userId = userData?.user?.id;
    const sponsorUser = userData?.user?.user_type === 'sponsor';

    const [message, setMessage] = useState('');

    const plansByProjectId = useSelector((state) => state.plansByProjectId.plans);

    useEffect(() => {
        dispatch(fetchPlansByProjectId(projectId));
    }, [dispatch, projectId]);
    
    let isAuthUser = false;
    let sortedPlans = [];
    let sortedBenefits = {};
    let planPriceDetails = {};
    let uniqueBenefits = [];
    let benefitMap = {};
    if (Array.isArray(plansByProjectId)) {
        isAuthUser = plansByProjectId?.some(plan => plan.user_id === userId);
   
        // Sort benefits by price and then by id and remove duplicates
        // Step 1: Sort the plans by first_price (convert string to number)
        sortedPlans = [...plansByProjectId].sort((a, b) => parseFloat(a.first_price) - parseFloat(b.first_price));

        // Step 2: For each plan, sort its benefits by benefit_id
        sortedBenefits = sortedPlans.flatMap(plan => {
            // Sort the benefits within each plan
            const sortedPlanBenefits = [...plan.plan_benefits].sort((a, b) => a.benefit_id - b.benefit_id);
            // Attach the plan's price to each benefit (to use later in rendering)
            return sortedPlanBenefits.map(benefit => ({
                ...benefit,
                plan_price: plan.first_price, // Include plan price for rendering
                plan_name: plan.plan_name,
                stripe_lookup_key: plan.plan_prices[0].stripe_lookup_key
            }));
        });

        // Step 3: Iterate through the sortedPlanBenefits array
        sortedBenefits.forEach(benefit => {
        const { description, plan_name } = benefit;

        // If the benefit with the same description is already in benefitMap, just add the plan_name
        if (benefitMap[description]) {
            benefitMap[description].plans.push(plan_name);
        } else {
            // Create a new entry for this benefit description and add it to result
            benefitMap[description] = { ...benefit, plans: [plan_name] };
            uniqueBenefits.push(benefitMap[description]);
        }
        });
        
        // Map to include plan_name, plan_id, and stripe_lookup_key
        planPriceDetails = plansByProjectId.flatMap(plan => ({
            plan_name: plan.plan_name,
            plan_id: plan.plan_id,
            plan_price: plan.plan_prices[0].plan_price,
            stripe_lookup_key: plan.plan_prices[0].stripe_lookup_key,
        }))
    };

    const handleSponsorNow = async (stripeLookupKey) => {
        if (userId && sponsorUser) {
            try {
                const response = await dispatch(createCheckoutSession({ userId, connectedAccountId, stripeLookupKey })).unwrap();
                window.location.href = response.url; // Redirect to the Stripe checkout page
              } catch (error) {
                console.error('Error creating checkout session:', error);
                // Handle error appropriately
              }
        } else if(!userId) {
            navigate('/login');
        } else {
            setMessage(t('plans.warning'));
        }
    };

    return (
        <>
        {plansByProjectId.length > 0 ? (
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th rowSpan="2">Description</th>
                        {planPriceDetails.map((plan, index) => (
                            <th key={index} className="text-center fs-3 text-sponsify-blue">
                                {plan.plan_name}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {planPriceDetails.map((plan, index) => (
                            <th key={index} className="text-center fw-normal text-sponsify-blue">
                                €{plan.plan_price}/{t('general.mo')}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {uniqueBenefits.map(benefit => (
                        <tr key={benefit.benefit_id}>
                            <td>{benefit.description}</td>
                            {planPriceDetails.map(planPriceDetail => (
                                <td key={planPriceDetail.plan_id} className="text-center">
                                    {benefit.plans.some(plan => (plan === planPriceDetail.plan_name)) ? <FontAwesomeIcon className="text-primary" icon="fa-solid fa-check" /> : ''}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td></td>
                        {planPriceDetails.map(plan => (
                        <td key={plan.plan_id} className="text-center">
                            {isAuthUser? (
                            <Link  
                                to={`/update-plan/${plan.plan_id}`}
                                type="button" 
                                className="btn btn-lg btn-block btn-primary"
                            >
                                {t('buttons.edit')}
                            </Link>
                        ) : (
                            <button  
                                onClick={() => handleSponsorNow(plan.stripe_lookup_key)} 
                                type="button" 
                                className="btn btn-lg btn-block btn-primary"
                            >
                                {t('buttons.sponsorNow')}
                            </button>
                        )}
                        </td>
                        ))}
                    </tr>
                </tfoot>       
            </table>
        ) : (
            <div className="container">{t('plans.sponsorshipPlanDisplay')}</div>
        )}
        {message && <div className="alert alert-warning">{message}</div>}
        </>
    );
};

export default PlansTable;