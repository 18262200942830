import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'; 
import { fetchOrdersByUserId } from '../../redux/slices/ordersByUserIdSlice';
import { fetchVouchersBySponsorUserId } from '../../redux/slices/vouchersBySponsorUserIdSlice';
import { fetchVoucherRedemptionsByUserId } from '../../redux/slices/voucherRedemptionsByUserIdSlice';
import { updateCancelAtStripeSubscription } from '../../redux/slices/updateCancelAtStripeSubscriptionSlice';
import { createStripeBillingPortal } from '../../redux/slices/createStripeBillingPortalSlice';
import { useTranslation } from 'react-i18next';

import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';

function SponsorDashboard() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData.user.id;
    const sponsorUser = userData.user.user_type === 'sponsor';

    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const orders = useSelector((state) => state.ordersByUserId.orders);
    const orderStatus = useSelector((state) => state.ordersByUserId.status);
    const vouchers = useSelector((state) => state.vouchersBySponsorUserId.vouchersBySponsorUserId);
    const error = useSelector((state) => state.ordersByUserId.error);

    useEffect(() => {
        if (userId) {
            dispatch(fetchOrdersByUserId(userId));
            dispatch(fetchVouchersBySponsorUserId(userId));
        }
        dispatch(fetchVoucherRedemptionsByUserId());
    }, [userId, dispatch]);

    const subscriptionsStatus = (order) => {
        if (order.order_cancel_at === null) {
            return 'active';
        } else if (new Date(order.order_cancel_at) > new Date()) {
            return `active until ${new Date(order.order_cancel_at).toLocaleDateString()}`;
        }
        return 'canceled';
    }
    

    if (orderStatus === 'loading') {
        return <div>Loading...</div>;
    }

    if (orderStatus === 'failed') {
        return <div>Error: {error}</div>;
    }

    const handleCancelPlan = (order) => {
        setSelectedOrder(order);
        setShowCancelConfirmation(true);
    }

    const confirmCancelPlan = () => {
        if (selectedOrder) {
            const { order_id, stripe_subscription_id } = selectedOrder;
            dispatch(updateCancelAtStripeSubscription({ orderId: order_id, stripeSubscriptionId: stripe_subscription_id }));
            setShowCancelConfirmation(false);
        }
    };

    const cancelCancelPlan = () => {
        setShowCancelConfirmation(false);
        setSelectedOrder(null);
    };

    const handleStripeBillingPortal = () => {
        dispatch(createStripeBillingPortal(userId)).then((result) => {
            if (result.payload) {
                window.location.href = result.payload;
            }
        });
    }

    const VoucherStatus = (createdAt, validMonths) => {
        const createdDate = new Date(createdAt);
        createdDate.setMonth(createdDate.getMonth() + validMonths);
        if (createdDate > new Date()) {
            return 'active';
        } else {
            return 'expired';
        }
    };

    return (
        <div className="container mt-5 pt-5">
            <h2 className="">{t('plans.mySubscriptions')}
                <span className="ms-3">
                    <button onClick={handleStripeBillingPortal} className="btn btn-primary">
                        {t('buttons.manageYourBillingInformation')}
                    </button>
                </span>
            </h2>
            <hr className="mb-3"/>
            <table className="table mb-5">
                <thead>
                    <tr>
                        <th>{t('grantees.grantee')}</th>
                        <th>{t('projects.projects')}</th>
                        <th>{t('plans.plan')}</th>
                        <th>{t('plans.price')}</th>
                        <th>{t('plans.status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders.length > 0 && orders?.map((order) => (
                        <tr key={order.order_id}>
                            <td>{order.grantee_name}</td>
                            <td>{order.project_name}</td>
                            <td>{order.plan}</td>
                            <td>€{order.price}/{t('general.mo')}</td>
                            <td>{subscriptionsStatus(order)}</td>
                            <td>
                                {order.cancel_at ? (
                                    ''
                                ) : (
                                    <button 
                                        onClick={() => handleCancelPlan(order)} 
                                        className={`${new Date(order.order_cancel_at) > new Date() ? 'disabled' : ''} btn btn-primary`}
                                    >
                                        {t('buttons.cancel')}
                                    </button>
                                )}
                            </td>                         
                        </tr>
                    ))}
                </tbody>
            </table>
            <Modal show={showCancelConfirmation} onHide={cancelCancelPlan}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('plans.planCancellation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t('plans.cancelPlan')}</Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-secondary" onClick={cancelCancelPlan}>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={confirmCancelPlan}>Confirm</button>
                </Modal.Footer>
            </Modal>

            <h2 className="">
                {t('vouchers.myVouchers')}
                {sponsorUser &&
                    <span className="ms-3">
                        <Link to={`/create-voucher`} className="btn btn-primary" type="button">{t('vouchers.createVoucher')}</Link>
                    </span>
                }
            </h2>
            <hr className="mb-3"/>
            <table className="table mb-5">
                <thead className="text-capitalize">
                    <tr>
                        <th>{t('vouchers.voucher')}</th>
                        <th>{t('general.createdAt')}</th>
                        <th>{t('vouchers.discount')}</th>
                        <th>{t('vouchers.purchasesOver')}</th>
                        <th>{t('vouchers.validFor')}</th>
                        <th>{t('plans.status')}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {vouchers.length > 0 && vouchers?.map((voucher) => (
                        <tr key={voucher.voucher_id}>
                            <td>{voucher.identifier}</td>
                            <td>{new Date(voucher.created_at).toLocaleDateString('en-GB')}</td>
                            <td>{parseFloat(voucher.discount.toString())}%</td>
                            <td>€{parseFloat(voucher.purchase_over.toString())}</td>
                            <td>{voucher.valid_months} months</td> 
                            <td>{VoucherStatus(voucher.created_at, voucher.valid_months)}</td>
                            <td>
                                <Link to={`/voucher-redemptions/${voucher.identifier}/${voucher.voucher_id}`} className="btn btn-primary" type="button">{t('vouchers.redemptions')}</Link>
                            </td>        
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )

}

export default SponsorDashboard;
  