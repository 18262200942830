import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for updating plan
export const updatePlan = createAsyncThunk('plan/updatePlan', async ({ updatedFormData, planId }) => {
      	const response = await fetch(`/api/plans/${planId}/update`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(updatedFormData)
		});
		if (!response.ok) {
			const errorText = await response.text();
			console.error('Network response was not ok:', errorText);
			throw new Error('Network response was not ok');
		}
		const data = await response.json();
		return data;
  });
  

const updatePlanSlice = createSlice({
	name: 'plan',
	initialState: { plan: '', status: 'idle', error: null },
	reducers: {},
	extraReducers: (builder) => {
		builder
		.addCase(updatePlan.pending, (state) => {
			state.status = 'loading';
		})
		.addCase(updatePlan.fulfilled, (state, action) => {
			state.status = 'succeeded';
			state.plan = action.payload;
		})
		.addCase(updatePlan.rejected, (state, action) => {
			state.status = 'failed';
			state.error = action.error.message;
		});
	},
});

export default updatePlanSlice.reducer;